<template>
  <div>
    <b-card-code title="Add Role">
      <b-card-text>
        <span>Add New Role </span>
      </b-card-text>
      <b-form novalidate class="needs-validation" @submit.prevent>
        <b-form-row>
          <!-- valid input  -->
          <b-col md="4" class="mb-3">
            <label for="input-valid1">Role Title:</label>
            <b-form-input
              id="input-valid1"
              v-model="data.role"
              :state="data.role.length > 0"
              placeholder="Valid input"
            />
          </b-col>
          <!-- submit button -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              @click="submit"
            >
              Submit
            </b-button>
          </b-col>
        </b-form-row>
      </b-form>
    </b-card-code>
    <!-- success handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showSuccessleAlert"
      @dismissed="showSuccessAlert = false"
      variant="success"
    >
      <h4 class="alert-heading">
        Success
      </h4>
      <div class="alert-body">
        <span>{{ success_message }}</span>
      </div>
    </b-alert>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'

import { codeValidateTooltips } from './code'
export default {
  components: {
    BCardCode,
  },
  
  
  data() {
    return {
      success_message: '',
      showDismissibleAlert: false,
      showSuccessleAlert: false,
      errors_back: [],
      data: {
        role: '',
      },
      codeValidateTooltips,
    }
  },
  methods: {
    // sumbit new role
    submit() {
      this.showDismissibleAlert = false
      this.showSuccessleAlert = false
      axios
        .post('role/create', this.data)
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
          this.$router.push('/Roles')
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
